@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tourney:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Text+Me+One&display=swap');
.body-component-a{
  background-color: rgb(28, 0, 46);
  background-image: none;
  overflow-y: scroll;
  overflow-x: visible;
  font-family: "Major Mono Display";
}
#portfoliotitle{
	font-family: "Righteous";
	font-size: 90px;
	color:  rgb(217, 255, 245);
	/* text-shadow: -2px 2px 8px grey,
				  2px 2px 8px grey,
				  2px -2px 0 grey,
				  -2px -2px 0 grey; */
	z-index: 2;
}
.head{
  font-family: "Righteous";
	font-size: 32px;
	color:  floralwhite;
  text-align: right;
  margin-right:2vw;
	/* text-shadow: -2px 2px 8px grey,
				  2px 2px 8px grey,
				  2px -2px 0 grey,
				  -2px -2px 0 grey; */
}
.explan{
  font-size: 20px;
  color: floralwhite;
  font-family: "Text Me One";
  text-align: justify;
  margin-left: 5vw;
  margin-right: 5vw;
}
@media only screen and (min-width: 768px) {
.explan{
  margin-left:30vw;
}
}
.partition{
  margin-left: 10%;
  text-align: left;
  font-size: 40px;
  color: whitesmoke;
  font-size:50px;
}
#shapegroup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  overflow-y: visible;
  overflow-x: visible;
}
.shapes {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: transparent url("./img/foggyshapes.png") center center;
  -webkit-background-clip:padding-box;
  background-clip:padding-box;
  opacity: 0.4;
  overflow-y: visible;
  overflow-x: visible;
}

#horizontalshape {
  animation: leftright infinite;
  animation-duration: 90s;
}
#verticalshape {
  animation: updown infinite;
  animation-duration: 64s;
}
#rotateleftshape {
  animation: spinl linear infinite;
  animation-duration: 96s;
  transform-origin: center center;
}
#rotaterightshape {
  animation: spinr linear infinite;
  animation-duration: 103s;
  transform-origin: center center;
}
@keyframes spinr {
  from {
    transform: rotate(50deg)
  }

  to {
    transform: rotate(410deg)
  }
}
@keyframes spinl {
  from {
    transform: rotate(382deg)
  }

  to {
    transform: rotate(22deg)
  }
}
@keyframes leftright {
  0% {
    left: -10vw;
  }

  50% {
    left: 10vw;
  }

  100% {
    left: -10vw;
  }
}
@keyframes updown {
  0% {
    bottom: -10vh;
  }

  50% {
    bottom: 10vh;
  }

  100% {
    bottom: -10vh;
  }
}


.vidButton{
  display:inline-block;
  padding:0.35em 1.2em;
  border:0.1em solid #FFFFFF;
  margin:0 0.3em 0.3em 0;
  border-radius:0.12em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Text Me One';
  font-weight:300;
  background-color:#ffffff00;
  color: #FFFFFF;
  text-align:center;
  transition: all 0.2s;
  }
.vidButton:hover{
  color:#000000;
  background-color:#FFFFFF;
}
.block{
  margin-left: 10%;
  margin-right: 10%;
  background-color: rgba(54, 54, 54, 0.274);
  /* border: 3px solid deeppink; */
  padding: 1%;
  overflow: hidden;
}
.vidplayer{
  float: left;
  border: none;
	width: 350px;
	height: 250px;
}

.back-button {
  position: absolute;
  left: 3%;
  top: 3%;
	width: 0;
	height: 0;
	border-top: 12px solid transparent;
	border-right: 25px solid rgb(114, 133, 127);
  border-left: transparent;
	border-bottom: 12px solid transparent;
  backface-visibility: hidden;
  background-color: transparent;
  padding: 0%;
  stroke: none;
  box-shadow: none;
}
.back-button:hover{
  border-right: 25px solid rgb(160, 201, 188);
}
@media only screen and (min-width: 768px) {
  .back-button{
    top: 5%;
    border-top: 20px solid transparent;
    border-right: 45px solid rgb(117, 153, 143);
    border-bottom: 20px solid transparent;
  }
  .back-button:hover{
    border-right: 45px solid rgb(160, 201, 188);
  }
}