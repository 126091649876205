.outerContainer {
  display:flow-root;
  padding:0;
  margin:0;
  top:0;
  left:0;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #1A1A1D;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 8px;
  height: 60%;
  width: 35%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .outerContainer {
    display:flow-root;
    padding:0;
    margin:0;
    top:0;
    left:0;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #1A1A1D;
  }

  /* .container {
    width: 100%;
    height: 100%;
  } */
}

@media (min-width: 480px) and (max-width: 1200px) {
  /* .container {
    width: 60%;
  } */
}
.shared-iframe{
  
  width:100%;
  height: auto;
}
.back-button {
  position: absolute;
  left: 3%;
  top: 3%;
	width: 0;
	height: 0;
	border-top: 12px solid transparent;
	border-right: 25px solid rgb(114, 133, 127);
  border-left: transparent;
	border-bottom: 12px solid transparent;
  backface-visibility: hidden;
  background-color: transparent;
  padding: 0%;
  stroke: none;
  box-shadow: none;
}
.back-button:hover{
  border-right: 25px solid rgb(160, 201, 188);
}
@media only screen and (min-width: 768px) {
  .back-button{
    top: 5%;
    border-top: 20px solid transparent;
    border-right: 45px solid rgb(117, 153, 143);
    border-bottom: 20px solid transparent;
  }
  .back-button:hover{
    border-right: 45px solid rgb(160, 201, 188);
  }
}