html, body {
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
}
.body-chat{
 background-color: #1A1A1D;
 background-image: none;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: #1A1A1D;
}

.joinInnerContainer {
  width: 20%;
}

.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
}

.heading {
  color: white;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

.button {
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #2979FF;
    padding: 20px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
  }


}

button:focus {
  outline: 0;
}
.back-button {
  position: absolute;
  left: 3%;
  top: 3%;
	width: 0;
	height: 0;
	border-top: 12px solid transparent;
	border-right: 25px solid rgb(114, 133, 127);
  border-left: transparent;
	border-bottom: 12px solid transparent;
  backface-visibility: hidden;
  background-color: transparent;
  padding: 0%;
  stroke: none;
  box-shadow: none;
}
.back-button:hover{
  border-right: 25px solid rgb(160, 201, 188);
}
@media only screen and (min-width: 768px) {
  .back-button{
    top: 5%;
    border-top: 20px solid transparent;
    border-right: 45px solid rgb(117, 153, 143);
    border-bottom: 20px solid transparent;
  }
  .back-button:hover{
    border-right: 45px solid rgb(160, 201, 188);
  }
}