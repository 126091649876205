@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

body{
	background-origin: border-box;
	background-image: linear-gradient(180deg, #4d78fc 50%, #5c4604 50%);
	background-size: 100vw 100vh;
/*	background-image: 
	  url("images/qps/grass.png"), url("images/qps/hills.png"),
	  url("images/qps/trees.png"), url("images/qps/mountains.png"), 
	  url("images/qps/sky.png"), linear-gradient(180deg, #4d78fc 50%, #5c4604 50%);
	background-position:  50% 140%, 50% 110%, 50% 85%, 50% 70%, 50% 35%, 50% 50%;
	background-repeat: repeat-x;
	background-size: auto 30%, auto 50%, auto 50%, auto 50%, auto 40%, 100% 200%;*/
	/*background-color: powderblue;*/
	text-align: center;
	align-content: center;
	overflow-x: hidden;
  overflow-y: hidden;
	position:relative;
}
.content{
	text-align: center;
	align-content: center;
	z-index: 100;
	overflow-x: hidden;
}
.paralax{
	display: none;
}
.paralaxmobile{
	position: static;
	top:0;
	left:0;
	bottom:0;
	right:0;
	height:120%;
	width:100%;
	overflow-x: hidden;
	overflow-y: hidden;
}
.paralaxmobile img{
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0vh;
	left: 0vw;
	object-fit: cover;
	z-index: -10;
	overflow-x: hidden;
	overflow-y: hidden;
}
#videoplayer{
	border: none;
	width: 420px;
	height: 345px;
}
#title {
  font-family: "MckloudBlack-32p";
  font-size: 32px;
  color: floralwhite;
  z-index: 2;
}
#construction {
  font-family: "Alphasmoke";
  font-size: 15px;
  color:floralwhite;
}
@media only screen and (min-width: 768px) {
	.paralaxmobile{
		display:none;
	}
	#title{
		font-size: 64px;
	}
	#construction{
		font-size: 30px;
	}
	.paralax{
		display:initial;
		width: 120%;
		height:120%;
		position: static;
		top:0;
		left:0;
		bottom:0;
		right:0;
		overflow-x: hidden;
		overflow-y: hidden;
	}
	.paralax img{
		position: absolute;
		width:130vw;
		height: 120vh;
		top: -15vh;
		left: -15vw;
		object-fit: cover;
		z-index: -10;
		overflow-x: hidden;
		overflow-y: hidden;
	}
}
p{
	font-family: courier;
}
.butt{
	display:inline-block;
	padding:0.35em 1.2em;
	border:0.1em solid #FFFFFF;
	margin:0 0.3em 0.3em 0;
	border-radius:0.12em;
	box-sizing: border-box;
	text-decoration:none;
	font-family:'Roboto',sans-serif;
	font-weight:300;
	color:#FFFFFF;
	text-align:center;
	transition: all 0.2s;
	}
.butt:hover{
	color:#000000;
	background-color:#FFFFFF;
}
.balloon{
	--animation-time: 60s;
	--x-float-start: 10vw;
	--y-float-start: 10vh;
	--x-float-end: 20vw;
	--y-float-end: 20vh;
	position: absolute;
  text-align: center;
  color: white;
	animation: float var(--animation-time) ease-in-out;
	animation-iteration-count: infinite;
	transform-origin: top left;
	overflow-x: hidden;
}
.balloontext{
	font-family: "VT323";
	font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
	transform: translate(-100%, -150%);
	color:#000000;
}
.balloontext:hover{
	color: whitesmoke;
}
.balloonbutt{
	background-color: transparent;
	border:none;
	cursor: pointer;
}
.balloonimg{
	width: 57px;
	height: 64px;
	overflow-x: hidden;
}
@media only screen and (max-width: 768px) {
	/* .balloon{
		animation-direction: alternate;
	} */
}
@media only screen and (min-width: 768px) {
	.balloontext{
		font-size: 26px;
		color: #000000;
	}
	.balloontext:link{
		color: #000000;
	}
	.balloontext:active{
		color: #000000;
	}
	.balloontext:visited{
		color: #000000;
	}
	.balloonimg{
		width: 100%;
		height: 100%;
	}

}
#sdtext{
	transform: translate(-50%, -100%);
}
#crtext{
	transform: translate(-50%, -100%);
}
#pbtext{
	transform: translate(-50%, -150%);
}
#fmtext{
	transform: translate(-50%, -135%);
	overflow: hidden;
    white-space: nowrap;
}
@keyframes float{
	0%{
		left: var(--x-float-start);
		top: var(--y-float-start);
	}
	100%{
		left: var(--x-float-end);
		top: var(--y-float-end);
	}
}
